<template>
  <div wrap class="elevation-2 mb-3">
    <v-toolbar flex dense class="elevation-1">
      <h4>Detalle de {{ singularName }}</h4>
      <v-spacer />
      <slot v-if="item" name="extra-buttons" :item="item" />
      <v-btn v-if="editView && edit" color="primary" dark  @click="goToEdit()">Editar</v-btn>
    </v-toolbar>
    <v-list>
      <slot name="extra-info"  v-if="item" :item="item"/>
      <slot name="item" v-if="item" :item="item" />
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    singularName: String,
    edit: {
      type: Boolean,
      default: true
    },
    editView: {
      type: String,
      default: ''
    },
    detailed: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      item: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    fetch () {
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(this.$api(this.url, this.detailed ? { detailed: null } : null))
          .then(({ data }) => (this.item = data))
          .catch(error => console.log(error.response))
      }
    },
    goToEdit () {
      this.$router.push({ name: this.editView, params: { id: this.item.id } })
    }
  }
}
</script>
