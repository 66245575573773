<template>
  <the-base-table
    ref="baseTable"
    :url="url"
    singularName="Documento asociado"
    pluralName="Documentos asociados"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="DocumentEdit"
    :objectDefault="DocumentDefault"
    :showActionsColumn="true"
    :create="true"
    :edit="false"
    :remove="true"
  >
    <template v-slot:form="{ isEditing }">
      <documents-form
        :url="url"
        :is-editing="isEditing"
        :document="DocumentEdit"
        @canceled="refresh()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>
    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td class="text-center">{{ item.get_document_name }}</td>
      <td class="text-center">{{ item.created_at }}</td>
    </template>
    <template v-slot:actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            rounded
            dark
            class="mx-2"
            color="success"
            :href="fullPath(item.document)"
            v-bind="attrs"
            v-on="on"
            target="_BLANK"
            title="Ver documento"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>Ver documento</span>
      </v-tooltip>
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import DocumentsForm from '@/components/bonus_affiliate/DocumentsForm'
export default {
  props: {
    url: String,
  },
  data: () => ({
    filter: {},
    DocumentEdit: {},
    DocumentDefault: {
      id: -1,
      document: null,
    },
    headers: [
      {
        text: 'ID',
        align: 'center',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Documento',
        align: 'center',
        sortable: false,
        value: 'get_document_name',
      },
      {
        text: 'Fecha de subida',
        align: 'center',
        sortable: true,
        value: 'created_at',
      },
    ],
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filters() {
      return this.filter
    },
  },
  mounted() {},
  methods: {
    fullPath(url) {
      return this.$apiWithoutSlash(url)
    },
    downloadFile(item) {
      this.axios({
        method: 'get',
        url: item.document,
        responseType: 'blob',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.get_document_name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() =>
          this.$store.dispatch(
            'message/error',
            'No se ha podido descargar el archivo'
          )
        )
    },
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
    },
    error(event) {
      console.log(event)
    },
  },
  components: {
    TheBaseTable,
    DocumentsForm,
  },
}
</script>
