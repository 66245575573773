<template>
  <the-base-detail
    ref="baseDetail"
    :url="url"
    singularName="Solicitud de bonificación"
    editView="bonus.edit"
    :detailed="true"
    :edit="true"
    :key="componentKey"
  >
    <template v-slot:extra-buttons></template>
    <template v-slot:extra-info="{ item }">
      <v-layout wrap class="pa-2 flex-row">
        <v-flex class="ma-2" xs12 md3 lg2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                color="accent"
                v-bind="attrs"
                v-on="on"
                :loading="loadingStates"
                title="Ver historial de estados"
                @click="load_states()"
              >
                <v-icon>mdi-radar</v-icon>Ver estados
              </v-btn>
            </template>
            <span>Historial de estados</span>
          </v-tooltip>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex
          v-if="
            isDraft === item.get_last_state_state 
          "
          class="ma-2"
          xs12
          md3
          lg2
        >
        
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                color="error darken-2"
                v-bind="attrs"
                v-on="on"
                title="Borrar Solicitud"
                @click="CreateStateDialog(item, 'Borrar')"
              >
                <v-icon>mdi-send</v-icon>Borrar
              </v-btn>
            </template>
            <span>Borrar solicitud</span>
          </v-tooltip>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex
          v-if="
            isDraft === item.get_last_state_state ||
              isRequestClarification === item.get_last_state_state
          "
          class="ma-2"
          xs12
          md3
          lg2
        >
        
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                color="success darken-2"
                v-bind="attrs"
                v-on="on"
                title="Enviar solicitud"
                @click="CreateStateDialog(item, 'Enviar')"
              >
                <v-icon>mdi-send</v-icon>Enviar
              </v-btn>
            </template>
            <span>Enviar solicitud</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:item="{ item }">
      <v-container>
        <v-flex>
          <v-alert
            v-if="showAlert"
            outlined
            type="warning"
            prominent
            border="right"
          >
            <div class="title">
              Estado: Solicita Aclaración
            </div>
            <span>mensaje:</span>
            {{ AlertMsg }}
          </v-alert>
        </v-flex>
      </v-container>
      <simple-detail :item="item" :order="bonus"></simple-detail>
      <template class="container">
        <documents-table :url="urlDocuments" />
      </template>
      <template class="container" v-if="false">
        <v-data-table
          :headers="headersDocuments"
          :items="item.documents"
          :items-per-page="5"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-2" dark>
              <v-toolbar-title>Documentos asociados</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  dark
                  class="mx-2"
                  color="success"
                  :href="item.document"
                  v-bind="attrs"
                  v-on="on"
                  target="_BLANK"
                  title="Ver documento"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver documento</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>

      <template
        class="container"
        v-if="Object.keys(item.dependents).length > 0"
      >
        <v-data-table
          :headers="headersDependents"
          :items="item.dependents"
          :items-per-page="5"
          class="container"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-2" dark>
              <v-toolbar-title>Cargas familiares asociadas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </template>

      <template v-if="Object.keys(states_history).length > 0">
        <v-data-table
          id="states_history"
          :headers="headersStates"
          :items="states_history"
          :items-per-page="5"
          class="container"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-2" dark>
              <v-toolbar-title>Historial estados de solicitud</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </template>

      <v-dialog v-model="isSending" persistent max-width="320">
        <v-card>
          <v-card-title class="headline"
            >Confirmación: {{ stateAction }}</v-card-title
          >
          <v-card-text
            >¿Está seguro que desea {{ stateAction }} esta solicitud de
            bonificación?*
            <p v-if="stateAction === 'Enviar'">
              No olvide subir los archivos de respaldo necesarios.
            </p>
            <p class="red--text">
              (*) Esta acción es irreversible
            </p></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" text @click="isSending = false"
              >Cancelar</v-btn
            >
            <v-btn
              v-if="stateAction === 'Enviar'"
              color="success darken-1"
              text
              @click="to_send(item)"
              >Aceptar</v-btn
            >
            <v-btn
              v-if="stateAction === 'Borrar'"
              color="error darken-1"
              text
              @click="to_delete(item)"
              >Borrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </the-base-detail>
</template>

<script>
import TheBaseDetail from '@/components/base/BaseDetail'
import SimpleDetail from '@/components/base/SimpleDetail'
import DocumentsTable from '@/components/bonus_affiliate/DocumentsTable'
import Utils from '@/utils'
export default {
  components: {
    TheBaseDetail,
    SimpleDetail,
    DocumentsTable,
  },
  data() {
    return {
      headersStates: [
        {
          text: 'Estado',
          align: 'center',
          sortable: true,
          value: 'get_state_display',
          width: '20%',
        },
        {
          text: 'Comentarios/Observaciones',
          align: 'center',
          sortable: true,
          value: 'comments',
          width: '60%',
        },
        {
          text: 'Fecha de creación',
          align: 'center',
          sortable: true,
          value: 'created_at',
          width: '20%',
        },
      ],
      headersDependents: [
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'id',
          width: '10%',
        },
        {
          text: 'Nombres',
          align: 'center',
          sortable: false,
          value: 'first_name',
        },
        {
          text: 'Apellidos',
          align: 'center',
          sortable: false,
          value: 'last_name',
        },
        {
          text: 'RUN',
          align: 'center',
          sortable: false,
          value: 'run',
        },
      ],
      headersDocuments: [
        {
          text: 'ID',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Documento',
          align: 'center',
          sortable: false,
          value: 'get_document_name',
        },
        {
          text: 'Fecha de subida',
          align: 'center',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Acciones',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      bonus: [
        {
          name: 'ID',
          key: 'id',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Nº de orden',
          key: 'order_number',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Fecha emisión de orden',
          key: 'order_date',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Nombre afiliado',
          key: 'get_affiliate_display_name',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'RUT afiliado',
          key: 'get_affiliate_display_run',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Previsión salud',
          key: 'get_coverage_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Monto total boleta/bono',
          key: 'requested_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Monto cobertura seguro de salud',
          key: 'coverage_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Valor servicio médico (ARANCEL FONASA)',
          key: 'bonus_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Monto a devolver',
          key: 'return_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Servicio médico',
          key: 'get_service_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Categoría de servicio médico',
          key: 'get_service_category_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Estado',
          key: 'get_last_state_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Método de pago',
          key: 'get_payment_method_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
      ],
      pagination: { rowsPerPage: -1 },
      loadingStates: false,
      stateAction: '',
      states_history: [],
      componentKey: 0,
      isSending: false,
      AlertMsg: '',
      showAlert: false,
      isDraft: Utils.BonusStates.DRAFT.id,
      isRequestClarification: Utils.BonusStates.REQUEST_CLARIFICATION.id,
    }
  },
  computed: {
    url() {
      return 'affiliate_bonus_list/' + this.$route.params.id
    },
    urlDocuments() {
      return 'affiliate_bonus_list/' + this.$route.params.id + '/documents/'
    },
  },
  created() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api(this.url + '/state_history'), {})
        .then(({ data }) => {
          const lastState = data[0]
          if (lastState.state === this.isRequestClarification) {
            this.showAlert = true
            this.AlertMsg = lastState.comments
          }
        })
        .catch(error => {
          this.$emit('error', error)
        })
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    CreateStateDialog(item, action) {
      this.isSending = true
      this.stateAction = action
    },
    error(event) {
      console.log(event)
    },
    load_states() {
      this.loadingStates = true
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(this.$api(this.url + '/state_history'), {})
          .then(({ data }) => {
            this.states_history = data
          })
          .catch(error => {
            this.$emit('error', error)
          })
          .then(() => {
            return this.$nextTick(() => {
              this.loadingStates = false
              this.$vuetify.goTo('#states_history')
            })
          })
      }
    },
    downloadFile(item) {
      this.axios({
        method: 'get',
        url: item.document,
        responseType: 'blob',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.get_document_name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() =>
          this.$store.dispatch(
            'message/error',
            'No se ha podido descargar el archivo'
          )
        )
    },
    to_send(item) {
      if (this.axios.defaults.headers.common.Authorization) {
        this.axios
          .get(this.$api(this.urlDocuments), {})
          .then(({ data }) => {
            if (data.length === 0) {
              this.$store.dispatch(
                'message/error',
                'Debe subir documentos asociados.'
              )
            } else {
              this.axios
                .post(this.$api(this.url + '/to_send'), {})
                .then(({ data }) => {
                  this.$emit('saved', data)
                  this.$store.dispatch(
                    'message/success',
                    'Esta solicitud ha sido Enviada con éxito'
                  )
                  this.forceRerender()
                })
                .catch(error => {
                  this.$emit('error', error)
                })
                .then(() => {
                  this.showAlert = false
                })
            }
            this.isSending = false
          })
          .catch(error => {
            this.$emit('error', error)
          })
      }
    },
    to_delete(item) {
      if (this.axios.defaults.headers.common.Authorization) {
        this.isSending = false
        this.axios
          .post(this.$api(this.url + '/to_delete'), {})
          .then(({ data }) => {
            this.$emit('saved', data)
            this.$store.dispatch(
              'message/success',
              'Esta solicitud ha sido Borrada con éxito'
            )
          })
          .catch(error => {
            this.$emit('error', error)
          })
          .then(() => {
            this.showAlert = false
            this.$router.back()

          })
        }
      }
    }
  }
</script>
