<template>
  <the-base-form
    :url="url"
    :object="clarification"
    :singularName="title"
    :is-editing="isEditing"
    reset-on-open
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md12>
      <v-container fluid>
        <ValidationProvider name="comments" v-slot="{ errors }">
          <v-textarea
            name="comments"
            clearable
            clear-icon="cancel"
            auto-grow
            label="Comentarios"
            v-model="clarification.comments"
            :value="clarification.comments"
            :error-messages="errors"
          ></v-textarea>
        </ValidationProvider>
      </v-container>
    </v-flex>
  </the-base-form>
</template>
<script>
import TheBaseForm from '@/components/base/BaseForm'
export default {
  data: () => ({}),
  components: {
    TheBaseForm,
  },
  props: {
    clarification: Object,
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    url() {
      return (
        'bonus_list/' + this.$route.params.id + '/to_request_clarification/'
      )
    },
    title() {
      return 'aclaración de información'
    },
  },
  watch: {},
  methods: {},
}
</script>
