import Vue from 'vue'

class UploadFilesService {
  upload (file, url, onUploadProgress) {
    const formData = new FormData()

    formData.append('document', file)

    return Vue.axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  getFiles (url) {
    return Vue.axios.get(url)
  }
}

export default new UploadFilesService()
