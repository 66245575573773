<template>
  <the-base-form
    :url="url"
    :object="document"
    :noSave="true"
    :multipart="true"
    cancelName="Cerrar"
    singularName="Documentación asociada"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md12>
      <v-row no-gutters justify="center" align="center">
        <v-col cols="8">
          <v-file-input
            counter
            v-model="document.document"
            small-chips
            multiple
            accept="image/png, image/jpeg, image/bmp, application/pdf"
            placeholder="Documentos de validación"
            prepend-icon="mdi-paperclip"
            label="Documentos de validación"
            :show-size="1000"
            truncate-length="30"
            @change="selectFiles"
            ><template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 5"
                color="deep-purple accent-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>
              <span
                v-else-if="index === 5"
                class="overline grey--text text--darken-3 mx-2"
              >
                +{{ document.document.length - 2 }} Archivo(s)
              </span>
            </template>
          </v-file-input>
        </v-col>

        <v-col cols="4" class="pl-2">
          <v-btn
            color="success"
            :loading="loading"
            dark
            small
            @click="uploadFiles"
          >
            Subir
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="document.document">
        <div
          class="mb-2"
          v-for="(progressInfo, index) in progressInfos"
          :key="index"
        >
          <span>{{ progressInfo.fileName }}</span>
          <v-progress-linear
            v-model="progressInfo.percentage"
            striped
            color="light-green"
            height="15"
            reactive
          >
            <strong>{{ progressInfo.percentage }} %</strong>
          </v-progress-linear>
        </div>
      </template>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'
import UploadService from '@/services/UploadFileService'

export default {
  data: () => ({
    loading: false,
    currentFile: undefined,
    selectedFiles: undefined,
    progressInfos: [],
    message: '',
    messages: [],
    fileInfos: [],
  }),
  components: {
    TheBaseForm,
  },
  props: {
    document: Object,
    url: String,
  },
  methods: {
    selectFiles(files) {
      this.progressInfos = []
      if (files.length > 0) this.selectedFiles = files
      else this.selectedFiles = undefined
    },
    uploadFiles() {
      this.loading = true
      this.message = ''
      this.messages = []

      if (this.selectedFiles !== undefined) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          this.upload(i, this.selectedFiles[i])
        }
      } else this.loading = false
    },
    upload(idx, file) {
      this.progressInfos[idx] = { percentage: 0, fileName: file.name }

      UploadService.upload(file, this.$api(this.url), event => {
        this.progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        )
      })
        .then(response => {
          // const prevMessage = this.message ? this.message : ''
          this.messages.push(
            ' Archivo ' + response.data.get_document_name + ' subido con éxito.'
          )
          this.$store.dispatch('message/success', this.messages)
          this.loading = false
          return UploadService.getFiles(this.$api(this.url))
        })
        .then(files => {
          this.fileInfos = files.data
          this.selectedFiles = undefined
          this.loading = false
        })
        .catch(e => {
          this.progressInfos[idx].percentage = 0
          this.$emit('error', e)
          this.$emit('form-save:error', 'create')
          this.$emit('create-form-save:error')
          this.loading = false
        })
    },
  },
}
</script>
