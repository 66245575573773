<template>
  <div id="solicitudesShow">
    <bonus-admin-detail v-if="(isAdmin)"/>
    <bonus-affiliate-detail v-if="(!isAdmin)"/>
  </div>
</template>

<script>

import BonusAdminDetail from '@/components/bonus_admin/BonusDetail'
import BonusAffiliateDetail from '@/components/bonus_affiliate/BonusDetail'
import Utils from '@/utils'
export default {
  components: {
    BonusAffiliateDetail,
    BonusAdminDetail
  },
  computed: {
    isAdmin () {
      // eslint-disable-next-line eqeqeq
      if (this.$store.getters['auth/user'].active_role == Utils.userRoles[2].id) {
        return true
      }
      return false
    }
  }
}
</script>
