<template>
  <v-container>
    <v-layout wrap>
      <v-flex
        v-for="prop in computedProps"
        :class="['prop', prop.class]"
        :key="prop.key"
      >
        <div class="prop-name">{{ prop.name }}</div>
        <router-link v-if="prop.to" :to="prop.to" class="prop-value ">{{
          prop.value
        }}</router-link>
        <div v-else class="prop-value">{{ prop.value }}</div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import get from 'lodash.get'
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Array,
    },
  },
  computed: {
    computedProps() {
      const item = this.item
      return this.order.map((property, index) => {
        const prop = {}
        if (typeof property === 'object') {
          prop.key = property.key
          prop.name =
            'name' in property ? property.name : this.keyToName(property.key)
          prop.class = property.class || 'xs12 sm6 md4 lg3'
          prop.to =
            typeof property.to === 'function'
              ? property.to(get(item, property.key), item)
              : property.to
          prop.value =
            typeof property.filter === 'function'
              ? property.filter(get(item, property.key), item)
              : get(item, property.key)
        } else {
          prop.key = property
          prop.name = this.keyToName(property)
          prop.value = get(item, property)
          prop.class = 'xs12 sm6 md4 lg3'
        }
        return prop
      })
    },
  },
  methods: {
    keyToName(key) {
      const string = key.trim().replace(/[-_]/g, ' ')
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>

<style scoped>
.prop-value {
  font-size: 16px;
}
.prop-name {
  font-size: 18px;
  color: rgba(45, 44, 49, 0.54);
}
</style>
