<template>
  <the-base-detail
    ref="baseDetail"
    :url="url"
    singularName="Solicitud de bonificación"
    
    :detailed="true"
    :edit="true"
    :key="componentKey"
  >
    <template v-slot:extra-buttons></template>
    <template v-slot:extra-info="{ item }">
      <v-layout wrap class="pa-2 flex-row">
        <v-flex class="ma-2" xs12 md3 lg2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                color="accent"
                v-bind="attrs"
                v-on="on"
                :loading="loadingStates"
                title="ver historial de estados"
                @click="load_states(item)"
              >
                <v-icon>mdi-radar</v-icon>Ver estados
              </v-btn>
            </template>
            <span>Historial de estados</span>
          </v-tooltip>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex class="ma-2" xs12 md3 lg2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                color="error darken-2"
                v-bind="attrs"
                v-on="on"
                block
                target="_BLANK"
                title="Rechazar solicitud"
                @click="CreateStateDialog(item, 'Rechazar')"
              >
                <v-icon>mdi-close-circle</v-icon>Rechazar
              </v-btn>
            </template>
            <span>Rechazar solicitud</span>
          </v-tooltip>
        </v-flex>
        <v-flex class="ma-2" xs12 md3 lg2>
          <v-dialog v-model="isEditing" persistent max-width="700px">
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                block
                color="info "
                v-on="on"
                title="Solicitar aclaración"
                @click="editItem(clarification)"
              >
                <v-icon>mdi-reply</v-icon>Aclarar
              </v-btn>
            </template>
            <clarification-form
              :isEditing="isEditing"
              :clarification="clarification"
              @canceled="stopEditing()"
              @saved="refresh()"
              @error="error($event)"
            />
          </v-dialog>
        </v-flex>
        <v-flex class="ma-2" xs12 md3 lg2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                color="success darken-2"
                v-bind="attrs"
                v-on="on"
                title="Aprobar solicitud"
                @click="CreateStateDialog(item, 'Aprobar')"
              >
                <v-icon>mdi-account-check</v-icon>Aprobar
              </v-btn>
            </template>
            <span>Aprobar solicitud</span>
          </v-tooltip>
        </v-flex>
        <v-flex class="ma-2" xs12 md3 lg2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                block
                color="success darken-4"
                v-bind="attrs"
                v-on="on"
                title="Bono Pagado"
                @click="CreateStateDialog(item, 'Indicar pagada')"
              >
                <v-icon>mdi-account-check</v-icon>Pagado
              </v-btn>
            </template>
            <span>Indicar bono pagado</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-container>
        <v-flex>
          <v-alert
            v-if="showAlert"
            outlined
            type="info"
            prominent
            border="right"
          >
            <div class="title">Recordatorio administración</div>
            <span
              >Debe ir al boton de "Editar" para agregar el valor
              correspondiente a la prestación médica según el libro de aranceles
              FONASA.</span
            >
            <div class="text-right"><v-btn color="primary" @click="edit">Editar</v-btn></div>
          </v-alert>
        </v-flex>
      </v-container>
    </template>
    <template v-slot:item="{ item }">
      <simple-detail :item="item" :order="bonus"></simple-detail>

      <template v-if="Object.keys(item.dependents).length > 0">
        <v-data-table
          :headers="headersDependents"
          :items="item.dependents"
          :items-per-page="5"
          class="container"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-2" dark>
              <v-toolbar-title>Cargas familiares asociadas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </template>

      <template v-if="Object.keys(item.documents).length > 0">
        <v-data-table
          :headers="headersDocuments"
          :items="item.documents"
          :items-per-page="5"
          class="container"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-2" dark>
              <v-toolbar-title>Documentos asociados</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  dark
                  class="mx-2"
                  color="success"
                  :href="item.document"
                  v-bind="attrs"
                  v-on="on"
                  target="_BLANK"
                  title="Ver documento"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver documento</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>

      <template v-if="Object.keys(states_history).length > 0">
        <v-data-table
          id="states_history"
          :headers="headersStates"
          :items="states_history"
          :items-per-page="5"
          class="container"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-2" dark>
              <v-toolbar-title>Historial estados de solicitud</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </template>

      <v-dialog v-model="isApprovingOrRejecting" persistent max-width="420">
        <v-card>
          <v-card-title class="headline"
            >Confirmación: {{ stateAction }}</v-card-title
          >
          <v-card-text
            >¿Está seguro que desea {{ stateAction }} esta solicitud de
            bonificación?</v-card-text
          >
          <v-spacer></v-spacer>
          <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
            <v-textarea
            name="comments"
            id="comments"
            label="Motivo"
            value=""
            hint="Motivo"
            v-model="comments"
          ></v-textarea>
            </v-col>
          </v-row>
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error darken-1"
              text
              @click="isApprovingOrRejecting = false"
              >Cancelar</v-btn
            >
            <v-btn
              v-if="stateAction === 'Aprobar'"
              color="success darken-1"
              text
              @click="to_approve(bonusObject)"
              >Aceptar</v-btn
            >
            <v-btn
              v-if="stateAction === 'Rechazar'"
              color="success darken-1"
              text
              @click="to_reject(bonusObject)"
              >Aceptar</v-btn
            >
            <v-btn
              v-if="stateAction === 'Indicar pagada'"
              color="success darken-1"
              text
              @click="to_paid(bonusObject)"
              >Aceptar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </the-base-detail>
</template>

<script>
import TheBaseDetail from '@/components/base/BaseDetail'
import SimpleDetail from '@/components/base/SimpleDetail'
import ClarificationForm from '@/components/bonus_admin/ClarificationForm'
import Utils from '@/utils'
export default {
  components: {
    TheBaseDetail,
    SimpleDetail,
    ClarificationForm,
  },
  data() {
    return {
      isEditing: false,
      showAlert: false,
      clarification: {
        id: -1,
        comments: '',
      },
      headersStates: [
        {
          text: 'Estado',
          align: 'center',
          sortable: true,
          value: 'get_state_display',
          width: '20%',
        },
        {
          text: 'Realizado por',
          align: 'center',
          sortable: true,
          value: 'get_creator_display',
          width: '20%',
        },
        {
          text: 'Comentarios/Observaciones',
          align: 'center',
          sortable: true,
          value: 'comments',
          width: '40%',
        },
        {
          text: 'Fecha de creación',
          align: 'center',
          sortable: true,
          value: 'created_at',
          width: '20%',
        },
      ],
      headersDependents: [
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'id',
          width: '10%',
        },
        {
          text: 'Nombres',
          align: 'center',
          sortable: false,
          value: 'first_name',
        },
        {
          text: 'Apellidos',
          align: 'center',
          sortable: false,
          value: 'last_name',
        },
        {
          text: 'RUN',
          align: 'center',
          sortable: false,
          value: 'run',
        },
      ],
      headersDocuments: [
        {
          text: 'ID',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Documento',
          align: 'center',
          sortable: false,
          value: 'get_document_name',
        },
        {
          text: 'Fecha de subida',
          align: 'center',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Acciones',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      bonus: [
        {
          name: 'ID',
          key: 'id',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Nº de orden',
          key: 'order_number',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Fecha emisión de orden',
          key: 'order_date',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Nombre afiliado',
          key: 'get_affiliate_display_name',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'RUT afiliado',
          key: 'get_affiliate_display_run',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Previsión salud',
          key: 'get_coverage_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Monto total boleta/bono',
          key: 'requested_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Monto cobertura seguro de salud',
          key: 'coverage_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Valor servicio médico (ARANCEL FONASA)',
          key: 'bonus_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Monto a devolver',
          key: 'return_amount',
          filter: this.$options.filters.currency,
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Servicio médico',
          key: 'get_service_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Categoría de servicio médico',
          key: 'get_service_category_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Estado',
          key: 'get_last_state_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
        {
          name: 'Método de pago',
          key: 'get_payment_method_display',
          class: 'xs12 sm6 md4 px-2 py-2',
        },
      ],
      pagination: { rowsPerPage: -1 },
      loading: false,
      loadingStates: false,
      states_history: [],
      componentKey: 0,
      isApprovingOrRejecting: false,
      stateAction: '',
      bonusObject: null,
      isSendState: Utils.BonusStates.SEND.id,
    }
  },
  created() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api(this.url + '/state_history'), {})
        .then(({ data }) => {
          const lastState = data[0]
          if (lastState.state === this.isSendState) {
            this.showAlert = true
          }
        })
        .catch((error) => {
          this.$emit('error', error)
        })
    }
  },
  computed: {
    url() {
      return 'bonus_list/' + this.$route.params.id
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    CreateStateDialog(item, action) {
      this.isApprovingOrRejecting = true
      this.bonusObject = item
      this.stateAction = action
    },
    editItem(item) {
      this.$emit('create:objectEdited', Object.assign({}, item))
      this.isEditing = true
    },
    refresh() {
      this.isEditing = false
      this.showAlert = false
      this.forceRerender()
    },
    stopEditing() {
      this.isEditing = false
    },
    error(event) {
      console.log(event)
    },
    load_states(item) {
      this.loadingStates = true
      this.axios
        .get(this.$api(this.url + '/state_history'), {})
        .then(({ data }) => {
          this.states_history = data
        })
        .catch((error) => {
          this.$emit('error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loadingStates = false
            this.$vuetify.goTo('#states_history')
          })
        })
    },
    to_paid(item) {
      this.isApprovingOrRejecting = false
      this.axios
        .post(this.$api(this.url + '/to_paid'), {comments: this.comments})
        .then(({ data }) => {
          this.$emit('saved', data)
          this.$store.dispatch(
            'message/success',
            'Esta solicitud ha indicada pagada con éxito'
          )
          this.comments = ""
          this.forceRerender()
        })
        .catch((error) => {
          this.$emit('error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    to_approve(item) {
      this.isApprovingOrRejecting = false
      this.axios
        .post(this.$api(this.url + '/to_approve'), {comments: this.comments})
        .then(({ data }) => {
          this.$emit('saved', data)
          this.$store.dispatch(
            'message/success',
            'Esta solicitud ha sido Aprobada con éxito'
          )
          this.comments = ""
          this.forceRerender()
        })
        .catch((error) => {
          this.$emit('error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    edit(){
      this.$router.push({ name: 'bonus.edit', params: { id: this.$route.params.id } })

    },
    to_reject(item) {
      this.isApprovingOrRejecting = false
      this.axios
        .post(this.$api(this.url + '/to_reject'), {comments: this.comments})
        .then(({ data }) => {
          this.$emit('saved', data)
          this.$store.dispatch(
            'message/success',
            'Esta solicitud ha sido Rechazada con éxito'
          )
          this.comments = ""
          this.forceRerender()
        })
        .catch((error) => {
          this.$emit('error', error)
        })
        .then(() => {
          return this.$nextTick(() => {
            this.loading = false
          })
        })
    },
    downloadFile(item) {
      this.axios({
        method: 'get',
        url: item.document,
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', item.get_document_name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() =>
          this.$store.dispatch(
            'message/error',
            'No se ha podido descargar el archivo'
          )
        )
    },
  },
}
</script>
